import React from 'react'
import eyeglass from '../assets/eyeglass.gif'
import telbot from '../assets/telbot.png'
import xbot from '../assets/xbot.png'
import line from '../assets/line.png'
import bottompic from '../assets/bottompic.png'
import sticker2 from '../assets/sticker2.gif'
import sticker from '../assets/sticker.gif'


function Joinus() {
    return (
        <div className='flex flex-col justify-center items-center'>

            <div className="relative bgjoin w-11/12 lg:rounded-[80px] rounded-3xl border-4 border-[#404040] box bg-white flex  flex-col justify-around items-center lg:p-10 p-5">
                <h2 className='lg:text-8xl text-4xl font-bold lg:my-8 my-2 text-center'>JOIN US ON XRP</h2>
                <p className='lg:text-xl text-center'>“Disclaimer: LandWolf is for entertainment purposes only. It’s not financial advice—seriously, we’re just wolves howling at the moon. Invest responsibly, and remember, the only guarantee here is that LandWolf runs on vibes and XRP. HODL tight and don’t blame us if your wallet grows fur!”</p>

                <img src={sticker2} alt="" className='lg:w-60  m-10 rounded-2xl' />

                <div className="social flex flex-row justify-center items-center gap-4 lg:mt-10 mt-4 flex-wrap">
                    <a href="https://t.me/XRPLandwolf" target="_blank" rel="noreferrer" className='lg:w-16 w-20 transition-transform hover:scale-95 active:scale-90'>
                        <img src={telbot} alt="" className='w-full' />
                    </a>
                    <a href="https://x.com/XRPLandwolf" target="_blank" rel="noreferrer" className='lg:w-16 w-20 transition-transform hover:scale-95 active:scale-90'>
                        <img src={xbot} alt="" className='w-full' />
                    </a>
                    <img src={eyeglass} alt="" className='lg:w-40 w-40 lg:ml-3 lg:ml-10 rounded-full' />
                </div>


                <img src={line} alt="" className=' w-11/12 lg:my-10 my-5
                 ' />
                <img src={sticker} alt="" className='lg:w-40 w-20 lg:absolute lg:bottom-10 bottom-16 lg:right-20 mb-5 lg:mb-0 rounded-3xl border-4 border-[#404040]' />

                <p className='lg:text-xl text-sm text-center '>2024 $XRPWOLF ALL RIGHTS RESERVED</p>
            </div>
        </div>
    )
}

export default Joinus