import React from 'react'
import wolfhero from '../assets/wolfhero.svg'
import buynowbtn from '../assets/buynowbtn.png'
import firstreal from '../assets/firstreal.png'
import dexbtn from '../assets/dexbtn.png'
import cigleft from '../assets/cigleft.png'
import cigright from '../assets/cigright.png'
import Header from './Header'

function Hero() {
    return (
        <div id='home' className="App relative flex flex-col justify-center items-center lg:mb-20 mb-10">


            <img src={wolfhero} alt="" className='lg:w-8/12 w-12/12' />
            <img src={firstreal} alt="" className='lg:w-9/12 w-11/12 lg:-mt-10 -mt-5' />

            <div className="twobtn w-full flex justify-around items-center lg:-mt-10 mt-1">
                <a href="https://dexscreener.com/xrpl/574f4c4600000000000000000000000000000000.r9d2zzkoc49xn6edksc5z4c6uoewujkadx_xrp" target="_blank" rel="noreferrer" className='lg:w-2/12 w-4/12 transition-transform hover:scale-95 active:scale-90'>
                    <img src={dexbtn} alt="" className='w-full' />
                </a>
                <a href="https://firstledger.net/token/r9D2ZZKoC49xN6EDKsc5Z4c6UoewUJkaDX/574F4C4600000000000000000000000000000000" target="_blank" rel="noreferrer" className='lg:w-2/12 w-4/12 transition-transform hover:scale-95 active:scale-90'>
                    <img src={buynowbtn} alt="" className='w-full' />
                </a>
            </div>
            <img src={cigleft} alt="" className='absolute left-0 -z-10 lg:w-96 w-20' />
            <img src={cigright} alt="" className='absolute right-0 -z-10 lg:w-96 w-20' />

        </div>
    )
}

export default Hero