import React from 'react'
import chain from '../assets/chain.png'
import buylandwolf from '../assets/buylandwolf.png'
import go from '../assets/go.png'
import get from '../assets/get.png'
import create from '../assets/create.png'
import roll1 from '../assets/roll1.gif'


function Howto() {
    return (
        <div id='how-to-buy' className='flex flex-col justify-center items-center'>
            <h2 className='lg:text-9xl text-5xl font-bold lg:my-8 my-8 text-white text-center'>HOW TO BUY</h2>

            <div className="howto flex flex-col lg:flex-row justify-center items-center gap-4 flex-wrap">
                <img src={create} alt="" className='lg:w-[40%] w-10/12' />
                <img src={get} alt="" className='lg:w-[40%] w-10/12' />
                <img src={go} alt="" className='lg:w-[40%] w-10/12' />
                <img src={buylandwolf} alt="" className='lg:w-[40%] w-10/12' />
            </div>

            <img src={roll1} alt="" className='lg:mt-24 lg:mb-20 mb-10 rotate-6 max-w-none lg:w-[1900px] w-[450px]' />

        </div>
    )
}

export default Howto