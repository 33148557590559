import { useRef, useState } from "react";
import Hero from "./components/Hero";
import First from "./components/First";
import About from "./components/About";
import Tokenomics from "./components/Tokenomics";
import Howto from "./components/Howto";
import Roadmap from "./components/Roadmap";
import Joinus from "./components/Joinus";
import Header from "./components/Header";
import bgmusic from './assets/audio.mp3';
import enter from './assets/enter.png';
import eyeglass from './assets/eyeglass.gif'


function App() {


  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const handlePlayMusic = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleToggleMute = () => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.muted = false;
      } else {
        audioRef.current.muted = true;
      }
      setIsMuted(!isMuted);
    }
  };



  return (
    <div className="relative   overflow-clip">


      {!isPlaying && (
        <div className="bg-black/60 backdrop-blur-xl fixed w-full h-screen flex flex-col justify-center items-center z-[60] ftop-10">
          {/* <img src={logo} alt="" className='w-60' /> */}
          <img
            src={eyeglass}
            alt="Enter"
            className="w-96 p-3 z-[60] cursor-pointer rounded-full"
            onClick={handlePlayMusic}
          />
          <p className="text-white text-2xl">Click to Enter</p>

        </div>
      )}
      <audio ref={audioRef} src={bgmusic} loop />





      <Header />
      <Hero />
      <About />
      <First />
      <Tokenomics />
      <Howto />
      <Roadmap />
      <Joinus />
    </div>
  );
}

export default App;
