import React from 'react'
import bottompic from '../assets/bottompic.png'
import telbot from '../assets/telbot.png'
import xbot from '../assets/xbot.png'
import eyeglass from '../assets/eyeglass.gif'


function Header() {
    return (
        <div className='flex justify-center items-center '>


            <div className='flex lg:justify-between lg:px-10 px-4 items-center lg:mb-20 mb-10 bg-white lg:w-8/12 w-10/12 m-3 border-black border-2 rounded-full p-1 fixed top-0 z-40'>


                <div className="together lg:flex hidden flex-row justify-center items-center gap-2">
                    <img src={bottompic} alt="" className='lg:w-14 w-40 lg:p-1' />
                    <p className='lg:text-xl  font-bold  '>XRPWOLF</p>
                </div>




                <div className=" gap-2  lg:text-lg text-sm hidden lg:flex">
                    <a href="#home" className="hover:text-[#AF5324]">HOME</a>
                    <a href="#about" className="hover:text-[#AF5324]">ABOUT</a>
                    <a href="#tokenomics" className="hover:text-[#AF5324]">TOKENOMICS</a>
                    <a href="#how-to-buy" className="hover:text-[#AF5324]">HOW TO BUY</a>
                </div>







                <div className="lg:w-auto w-full social flex flex-row justify-center  items-center lg:gap-2 gap-2 ">
                    <a href="https://t.me/XRPLandwolf" target="_blank" rel="noreferrer" className=' transition-transform hover:scale-95 active:scale-90'>
                        <img src={telbot} alt="" className='lg:w-10 w-8' />
                    </a>
                    <a href="https://x.com/XRPLandwolf" target="_blank" rel="noreferrer" className=' transition-transform hover:scale-95 active:scale-90'>
                        <img src={xbot} alt="" className='lg:w-10 w-8' />
                    </a>
                    <img src={eyeglass} alt="" className='lg:w-32 w-20 lg:ml-3 ml-10 rounded-full' />
                </div>

            </div>
        </div>
    )
}

export default Header