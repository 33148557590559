import React from 'react'
import roadmapp from '../assets/roadmapp.png'

function Roadmap() {
    return (
        <div className='flex flex-col justify-center items-center lg:mb-20 mb-10'>
            <h2 className='lg:text-9xl text-5xl font-bold lg:my-8 my-8 text-white text-center'>ROADMAP</h2>

            <img src={roadmapp} alt="" className='lg:w-[80%] w-10/12' />

        </div>
    )
}

export default Roadmap