import React from 'react'
import heavenwolf from '../assets/heavenwolf.png'
import roll1 from '../assets/roll1.gif'

function About() {
    return (
        <div id='about' className="App relative flex flex-col justify-center items-center">


            <div className="bgimg w-11/12 lg:rounded-[80px] rounded-3xl border-4 border-[#404040] box bg-white flex lg:flex-row flex-col justify-around items-center lg:p-10 p-5">


                <div className="flex flex-col justify-center items-start lg:w-5/12">
                    <h2 className='lg:text-8xl text-5xl font-bold my-5'>XRPWOLF?</h2>
                    <p className='lg:text-xl'>LandWolf is not just another crypto project it’s a movement. Built on the XRP Ledger, LandWolf is designed for the long haul, with a dedicated team committed to creating real value and building a thriving community. We’re not here for a quick pump-and-dump, and we’re definitely not like the Solana LandWolf a bunch of jeets chasing short-term gains.
                        <br />
                        <br />
                        Our team includes talented artists and marketing experts, ensuring LandWolf stands out and reaches its full potential. Backed by the unstoppable XRP Army, LandWolf is poised to dominate with innovation, resilience, and strength. Join the pack, and let’s lead the charge together.</p>
                </div>


                <img src={heavenwolf} alt="" className='lg:w-6/12 m-5 lg:m-0' />

            </div>

            <img src={roll1} alt="" className='lg:mt-10 lg:mb-20 mb-10 rotate-6 max-w-none lg:w-[1900px] w-[450px]' />

        </div>
    )
}

export default About