import React from 'react'
import firstpic from '../assets/firstpic.png'
import ca from '../assets/ca.png'
import roll1 from '../assets/roll1.gif'



function First() {
    return (
        <div>
            <div className='flex flex-col justify-center items-center relative'>
                <img src={firstpic} alt="" className='lg:w-11/12 w-12/12' />

                <div className="hello flex flex-col justify-center items-center absolute lg:bottom-20 bottom-5 bg-[#96471E]/60 lg:w-9/12 w-11/12 lg:p-10 p-3 backdrop-blur-lg">
                    <h2 className='lg:text-6xl text-2xl font-bold lg:my-5 my-2 text-white text-center'>FIRST WOLF ON THE XRP BLOCKCHAIN</h2>

                    <img
                        src={ca}
                        alt=""
                        className='lg:w-9/12 w-12/12 cursor-pointer transition-transform duration-300 hover:scale-105 active:scale-95'
                        onClick={() => {
                            navigator.clipboard.writeText('r9D2ZZKoC49xN6EDKsc5Z4c6UoewUJkaDX');
                        }}
                    />

                </div>

            </div>
            <img src={roll1} alt="" className='lg:mt-10 lg:mb-20 mb-10 -rotate-6 max-w-none lg:w-[1900px] w-[450px]' />

        </div>
    )
}

export default First