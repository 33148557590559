import React from 'react'
import chain from '../assets/chain.png'
import contract from '../assets/contract.png'
import lp from '../assets/lp.png'
import supply from '../assets/supply.png'

function Tokenomics() {
    return (
        <div id='tokenomics' className='flex flex-col justify-center items-center'>
            <h2 className='lg:text-9xl text-2xl font-bold lg:my-5 my-2 text-white text-center'>TOKENOMICS</h2>

            <div className="tokenomics flex flex-col lg:flex-row justify-center items-center gap-4">
                <img src={chain} alt="" className='lg:w-80 w-10/12' />
                <img src={contract} alt="" className='lg:w-80 w-10/12' />
                <img src={lp} alt="" className='lg:w-80 w-10/12' />
                <img src={supply} alt="" className='lg:w-80 w-10/12' />
            </div>
        </div>
    )
}

export default Tokenomics